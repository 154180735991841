/**
 * This file is used to store application constants.
 * Messages / button labels displayed on UI will not be part of this file.
 */

// APP Version
export const APP_VERSION = '1.110.1';
export const DEFAULT_LOG_VERSION = 'v1';

// India Document Types key
export const IND_PAN_KEY = 'ind_pan';
export const IND_AADHAAR_KEY = 'ind_aadhaar';
export const IND_DRIVING_LICENSE_KEY = 'ind_driving_license';
export const IND_VOTER_KEY = 'ind_voter_id';
export const IND_PASSPORT_KEY = 'ind_passport';
export const IND_GST_CERTIFICATE_KEY = 'ind_gst_certificate';
export const WET_SIGNATURE_KEY = 'wet_signature';

// Philippines Document Types keys
export const PHL_VOTER_KEY = 'phl_voter';
export const PHL_DRIVING_LICENSE_KEY = 'phl_driving_license';
export const PHL_PASSPORT_KEY = 'phl_passport';
export const PHL_TIN_KEY = 'phl_tin';
export const PHL_SSS_KEY = 'phl_sss';
export const PHL_NBI_KEY = 'phl_nbi';
export const PHL_PHILSYS_KEY = 'phl_philsys';
export const PHL_PHILHEALTH_KEY = 'phl_philhealth';
export const PHL_UMID_KEY = 'phl_umid';
export const PHL_PRC_KEY = 'phl_prc';
export const PHL_PHILPOST_KEY = 'phl_philpost';
export const PHL_EPHIL_KEY = 'phl_ephil';

// Indonesia Document Type keys
export const IDN_KTP_KEY = 'idn_ktp';

export const CAPTURE_SELFIE = 'Selfie';
export const DOCUMENT_TYPE_SELFIE = 'selfie';

export const DOCUMENT_TYPES: Map<string, string> = new Map([
  [IND_PAN_KEY, 'IND_PAN_LABEL'],
  [IND_AADHAAR_KEY, 'IND_AADHAAR_LABEL'],
  [IND_DRIVING_LICENSE_KEY, 'IND_DRIVING_LICENSE_LABEL'],
  [IND_VOTER_KEY, 'IND_VOTER_LABEL'],
  [IND_PASSPORT_KEY, 'IND_PASSPORT_LABEL'],
  [IND_GST_CERTIFICATE_KEY, 'IND_GST_CERTIFICATE_LABEL'],
  [WET_SIGNATURE_KEY, 'WET_SIGNATURE_LABEL'],
  [PHL_VOTER_KEY, 'PHL_VOTER_LABEL'],
  [PHL_DRIVING_LICENSE_KEY, 'PHL_DRIVING_LICENSE_LABEL'],
  [PHL_PASSPORT_KEY, 'PHL_PASSPORT_LABEL'],
  [PHL_SSS_KEY, 'PHL_SSS_LABEL'],
  [PHL_TIN_KEY, 'PHL_TIN_LABEL'],
  [PHL_NBI_KEY, 'PHL_NBI_LABEL'],
  [PHL_PRC_KEY, 'PHL_PRC_LABEL'],
  [PHL_PHILHEALTH_KEY, 'PHL_PHILHEALTH_LABEL'],
  [PHL_PHILSYS_KEY, 'PHL_PHILSYS_LABEL'],
  [PHL_UMID_KEY, 'PHL_UMID_LABEL'],
  [PHL_PHILPOST_KEY, 'PHL_PHILPOST_LABEL'],
  [PHL_EPHIL_KEY, 'PHL_EPHIL_LABEL'],
  [IDN_KTP_KEY, 'IDN_KTP_LABEL'],
  [DOCUMENT_TYPE_SELFIE, 'CAPTURE_SELFIE_HEADER'],
]);

// Image keys
export const SIDE_FRONT_KEY = 'front';
export const SIDE_BACK_KEY = 'back';

export const IMAGE_LABEL: Map<string, string> = new Map([
  [SIDE_FRONT_KEY, 'SIDE_FRONT_LABEL'],
  [SIDE_BACK_KEY, 'SIDE_BACK_LABEL'],
]);

// IndvCaptureStatus
export const STATUS_APPROVED = 'approved';
export const STATUS_REJECTED = 'rejected';
export const STATUS_CANCELLED = 'cancelled';
export const STATUS_COMPLETED = 'completed';
export const STATUS_CAPTURE_EXPIRED = 'capture_expired';
export const STATUS_RECAPTURE_EXPIRED = 'recapture_expired';
export const STATUS_FAILED = 'failed';
export const STATUS_PURGED = 'purged';
export const STATUS_IN_PROGRESS = 'in_progress';
export const STATUS_CAPTURE_DECISION = 'capture_decision';
export const STATUS_CAPTURE_PROCESSING = 'capture_processing';
export const STATUS_INITIATED = 'initiated';
export const STATUS_PROCESSED = 'processed';
export const STATUS_PENDING = 'pending';
export const STATUS_REVIEW_ON_HOLD = 'review_on_hold';
export const STATUS_REVIEW_REQUIRED = 'review_required';
export const STATUS_REVIEW_PROCESSING = 'review_processing';
export const STATUS_CAPTURE_PENDING = 'capture_pending';
export const STATUS_RECAPTURE_PENDING = 'recapture_pending';
export const STATUS_REVIEW = 'review';

// Classnames for color coding status text
export const STATUS_SUCCESS_CLASSNAME = 'success';
export const STATUS_FAILED_CLASSNAME = 'failed';
export const STATUS_PENDING_CLASSNAME = 'pending';

// Categories by capture status
export const STATUS_CATEGORY_COMPLETED = 'completed';
export const STATUS_CATEGORY_LINK_EXPIRED = 'link_expired';
export const STATUS_CATEGORY_TRANSIENT = 'transient';
export const STATUS_CATEGORY_UNDER_REVIEW = 'under_review';

export const COLOR_CODE: Map<string, string> = new Map([
  [STATUS_APPROVED, STATUS_SUCCESS_CLASSNAME],
  [STATUS_REJECTED, STATUS_FAILED_CLASSNAME],
  [STATUS_CANCELLED, STATUS_FAILED_CLASSNAME],
  [STATUS_CAPTURE_EXPIRED, STATUS_FAILED_CLASSNAME],
  [STATUS_RECAPTURE_EXPIRED, STATUS_FAILED_CLASSNAME],
  [STATUS_FAILED, STATUS_FAILED_CLASSNAME],
  [STATUS_PURGED, STATUS_FAILED_CLASSNAME],
  [STATUS_IN_PROGRESS, STATUS_PENDING_CLASSNAME],
  [STATUS_CAPTURE_DECISION, STATUS_PENDING_CLASSNAME],
  [STATUS_CAPTURE_PROCESSING, STATUS_PENDING_CLASSNAME],
  [STATUS_INITIATED, STATUS_PENDING_CLASSNAME],
  [STATUS_PROCESSED, STATUS_PENDING_CLASSNAME],
  [STATUS_PENDING, STATUS_PENDING_CLASSNAME],
  [STATUS_REVIEW_ON_HOLD, STATUS_PENDING_CLASSNAME],
  [STATUS_REVIEW_REQUIRED, STATUS_PENDING_CLASSNAME],
  [STATUS_REVIEW_PROCESSING, STATUS_PENDING_CLASSNAME],
  [STATUS_CAPTURE_PENDING, STATUS_PENDING_CLASSNAME],
  [STATUS_RECAPTURE_PENDING, STATUS_PENDING_CLASSNAME],
  [STATUS_REVIEW, STATUS_PENDING_CLASSNAME],
]);

export const STATUS_CATEGORY_MAPPING: Map<string, string> = new Map([
  [STATUS_APPROVED, STATUS_CATEGORY_COMPLETED],
  [STATUS_REJECTED, STATUS_CATEGORY_COMPLETED],
  [STATUS_COMPLETED, STATUS_CATEGORY_COMPLETED],
  [STATUS_CANCELLED, STATUS_CATEGORY_LINK_EXPIRED],
  [STATUS_CAPTURE_EXPIRED, STATUS_CATEGORY_LINK_EXPIRED],
  [STATUS_RECAPTURE_EXPIRED, STATUS_CATEGORY_LINK_EXPIRED],
  [STATUS_FAILED, STATUS_CATEGORY_LINK_EXPIRED],
  [STATUS_PURGED, STATUS_CATEGORY_LINK_EXPIRED],
  [STATUS_IN_PROGRESS, STATUS_CATEGORY_TRANSIENT],
  [STATUS_CAPTURE_DECISION, STATUS_CATEGORY_TRANSIENT],
  [STATUS_CAPTURE_PROCESSING, STATUS_CATEGORY_TRANSIENT],
  [STATUS_INITIATED, STATUS_CATEGORY_TRANSIENT],
  [STATUS_PROCESSED, STATUS_CATEGORY_TRANSIENT],
  [STATUS_PENDING, STATUS_CATEGORY_TRANSIENT],
  [STATUS_REVIEW_ON_HOLD, STATUS_CATEGORY_UNDER_REVIEW],
  [STATUS_REVIEW_REQUIRED, STATUS_CATEGORY_UNDER_REVIEW],
  [STATUS_REVIEW_PROCESSING, STATUS_CATEGORY_UNDER_REVIEW],
]);

// Image Picker
export const IMAGE_JPEG = 'image/jpeg';
export const IMAGE_PNG = 'image/png';
export const IMAGE_JPG = 'image/jpg';

// Page Sequence
export const PAGE_SEQ_INTRO = 'introduction';
export const PAGE_SEQ_CONSENT = 'consent';
export const PAGE_SEQ_CAPTURE = 'capture';
export const PAGE_SEQ_PERMISSIONS = 'permissions';
export const PAGE_SEQ_AVKYC = 'vkyc.assisted_vkyc';
export const PAGE_SEQ_SV_SERVICE = 'self_video';
export const PAGE_SEQ_AV_SKILL_SELECT = 'av_skill_select';
export const MOVE_TO_NEXT_PAGE = 'next_page';
export const PAGE_SEQ_AVKYC_CALL_WAITING = 'call_waiting';
export const PAGE_SEQ_IN_VIDEO_CALL = 'in_video_call';
export const PAGE_SEQ_CAPTURE_PREREQUISITE = 'capture_prerequisite';
export const PAGE_SEQ_AV_PREREQUISITE = 'av_prerequisite';
export const PAGE_SEQ_SV_PREREQUISITE = 'sv_prerequisite';
export const PAGE_SEQ_GENERIC_PAGE = 'generic_page';
export const PAGE_TYPE_NOTES = 'notes';

export const VALID_PAGES = [
  PAGE_SEQ_INTRO,
  PAGE_SEQ_CONSENT,
  PAGE_SEQ_CAPTURE,
  PAGE_SEQ_PERMISSIONS,
  PAGE_SEQ_AVKYC,
  PAGE_SEQ_SV_SERVICE,
  PAGE_SEQ_AV_SKILL_SELECT,
  PAGE_SEQ_AVKYC_CALL_WAITING,
  PAGE_SEQ_IN_VIDEO_CALL,
  PAGE_SEQ_CAPTURE_PREREQUISITE,
  PAGE_SEQ_AV_PREREQUISITE,
  PAGE_SEQ_AV_PREREQUISITE,
  PAGE_TYPE_NOTES,
  PAGE_SEQ_GENERIC_PAGE,
];

// DIV
export const DIV_STATUS_DONE = 'div_done';
export const DIV_STATUS_PENDING = 'div_pending';
export const DIV_STATUS_TIMED_OUT = 'div_timeout';
export const DIV_TIMEOUT = 15000;

export const DOC_FETCHER_TIMEOUT = 180000;
export const DOC_FETCHER_TAB_CLOSED_CHECK_INTERVAL = 1000;
export const DOC_FETCHER_OVERLAY_CLEAR_TIMEOUT = 5000;

// Pan Tampering
export const PAN_TAMPER_STATUS_DONE = 'pan_tampering_done';
export const PAN_TAMPER_STATUS_PENDING = 'pan_tampering_pending';
export const PAN_TAMPER_STATUS_TIMED_OUT = 'pan_tamper_timeout';
export const PAN_TAMPER_TIMEOUT = 15000;

// ErrorHandler
export const ERROR_TYPE_TIMEOUT = 'timeOut';
export const ERROR_TYPE_SOMETHING_WENT_WRONG = 'somethingWentWrong';
export const ERROR_TYPE_INVALID_LINK = 'invalidLink';
export const ERROR_TYPE_INVALID_LOCATION = 'location';
export const ERROR_TYPE_COMPATIBILITY = 'compatibility';
export const ERROR_TYPE_BROWSER_NOT_SUPPORTED = 'browser_not_supported';
export const ERROR_TYPE_LOCATION_DENIED = 'location_denied';
export const ERROR_TYPE_NO_AGENT = 'no_agent';
export const ERROR_TYPE_REMOVED_FROM_QUEUE = 'removed_from_queue';
export const ERROR_TYPE_SLOTS_UNAVAILABLE = 'no_slots';
export const ERROR_TYPE_PROXY_CHECK = 'proxy_check';
export const SHOW_REDIRECTING_OVERLAY = 'show_redirecting_overlay';
export const DF_AUTHENTICATION_FAILED = 'df_authentication_failed';
export const HEALTH_CHECK_FAILED = 'health_check_failed';
export const HEALTH_CHECK_WARNING = 'health_check_warning';
export const LINK_UNAVAILABLE = 'link_unavailable';
export const OUT_OF_OFFICE = 'out_of_office';
export const VIDEO_TASK_INVALID = 'video_task_not_in_valid_state';
export const SCHEDULING_SERVICE = 'scheduling_service';

// FaceAPI
export const MODEL_URL = '/models';
export const FACE_DETECTION_INTERVAL = 500;
export const MAX_FACE_DETECTION_ATTEMPTS = 3;

// AVKYC Agent Unavailable
export const NO_VKYC_AGENTS = 'no_agents';
export const VKYC_AGENT_NO_ACTION = 'no_action';
export const VKYC_AGENT_BUSY = 'busy_on_call';
export const VKYC_AGENT_OFFLINE = 'offline';
export const VKYC_AGENT_CALL_DECLINED = 'declined';

export const END_CALL_CONFIRMATION =
  'Are you sure you want to end the video call?';
export const END_CALL_TITLE = 'Warning!';

// Permissions
export const ASK_PERMISSION_BTN_LABEL = 'ALLOW PERMISSIONS';
export const FILE_TYPE_NOT_SUPPORTED = 'Please upload valid file';
export const UPLOAD_FILE_LABEL = 'Upload File';
export const LOCATION = 'location';
export const CAMERA = 'camera';
export const MICROPHONE = 'microphone';

export const PROVIDER_ANTMEDIA = 'antmedia';

// AvKYC Task Status
export const AVKYC_TASK_STATUS_PENDING = 'pending';
export const AVKYC_TASK_STATUS_IN_PROGRESS = 'in_progress';
export const AVKYC_TASK_STATUS_COMPLETED = 'completed';
export const AVKYC_TASK_STATUS_IN_SESSION = 'in_session';

// AvKYC End Mechanism
export const AVKYC_ENDED_BY_USER = 0;
export const AVKYC_ENDED_BY_MEDIA_SERVER = 1;
export const AVKYC_ENDED_BY_SCHEDULING = 2;
export const AVKYC_ENDED_BY_ABORTING = 3;
export const AVKYC_ENDED_BY_USER_IN_BACKGROUND = 4;
export const AVKYC_ENDED_BY_AGENT = 5;
export const AVKYC_ENDED_BY_DROPPING = 6;

// Assited Video end call reasons
export enum CALL_END_REASONS {
  USER_SCHEDULED = 'user scheduled call',
  AGENT_UNAVAILABLE = 'agents unavailable',
  USER_ENDED = 'user ended call',
  AGENT_DISCONNECTED = 'agent disconnected',
  USER_REMOVED_FROM_QUEUE = 'user removed from queue',
}

export const NO_AUDIO_NO_VIDEO_TIMEOUT_VALUE = 2000;

// OFFLINE KYC PAGE
export const TEST_CAPTCHA_LINK =
  'https://storage.googleapis.com/flairlabs-dev/d35ab5ed-6cbb-4371-603c-cad01c656e56.jpeg?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=flairlabs-dev%40idfy-eve-staging.iam.gserviceaccount.com%2F20200217%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20200217T073643Z&X-Goog-Expires=599&X-Goog-Signature=0caf253860b60214465bc5a59023f3b83884fad67c070499b5b7c98e93ce824b829835c5e1135e083537812d7e5b644537e4bcf2fd98d1c59ee01c3b9f8f38f109e8df12e1fcd8e796ccbd502275e6995b050c160f00aac94f49183395ecdbbf9ccf769aaff3fa2000dd75821c7f3a68755f81ab9546b14be4846e335f5e1401dcfcee7a7aec50e609fdb6aed7612de6cdc781eb674e6c7db7cbd8a21252ad62c3025385f2e013dadc19341c84ff494414f835560ba317c39754c956b34b85abe1588e91e4418137b7d1cbc0a375e7182e9495267a7abe2835137a7a364fa301c941912e6cca1b1c7ac057329ed609255a83eeac9c0e229cad5b926b9dfc179e&X-Goog-SignedHeaders=host';

// Task Status
export const CHECKS_STATUS_VERIFYING = 'checks_verifying';
export const CHECKS_STATUS_DONE = 'completed';
export const CHECKS_STATUS_PENDING = 'pending';
export const CHECKS_STATUS_TIMED_OUT = 'checks_timed_out';

// Helper
export const MIME_TYPE_ZIP = 'application/zip';

// DataService
export const ARTIFACT_DETAILS_KEY_IN_STORE = 'ArtifactDetails';
export const TASKS_INFO_KEY_IN_STORE = 'TasksInfo';

// SVC
export const SELF_VKYC_URI = 'https://video-kyc.idfystaging.com/video_kycs/';

// SVS Task Status
export const SV_SERVICE_TASK_STATUS_COMPLETED = 'completed';
export const SV_SERVICE_TASK_STATUS_IN_PROGRESS = 'in_progress';
export const SV_FACE_DETECTION_INTERVAL = 1000;

export const READ_RANDOM_DIGITS = 'read_random_digits';
export const READ_TEXT = 'read_text';

// Validations
export const VALIDATION_LOCATION = 'location';
export const VALIDATION_IP = 'ip_location';

// Permissions
export const PERMISSION_GRANTED = 'granted';
export const PERMISSION_DENIED = 'denied';

// location permission denied
export const LOCATION_SUCCESS = 0;
export const LOCATION_PERMISSION_DENIED = 1;
export const LOCATION_UNAVAILABLE = 2;
export const LOCATION_TIMEOUT = 3;
export const PROXY_CHECK_SUCCESS = 4;

// Location fetch config
export const GEOLOCATION_TIMEOUT_WITH_FALLBACK = 5000;
export const GEOLOCATION_TIMEOUT_WITHOUT_FALLBACK = 30000;
export const GEOLOCATION_MAXIMUM_AGE = 0;

// OS
export enum OS_NAME {
  'ANDROID' = 'Android',
  'LINUX' = 'Linux',
  'WINDOWS' = 'Windows',
  'MAC' = 'Mac OS',
  'iOS' = 'iOS',
  'WINDOWS_PHONE' = 'Windows Phone',
}

export enum VIDEO_TASK_IN_INVALID_STATE_TRANSLATION_KEYS {
  'assisted_video' = 'CALL_STILL_IN_SESSION',
  'self_video' = 'SV_TASK_NOT_IN_PROGRESS',
}

// WebSocket
export enum IDFY_SOCKET_EVENT {
  'DISCONNECTED' = 'disconnected',
  'SOCKET_STATE' = 'socketState',
  'CONNECTED' = 'socketConnected',
  'CHANNEL_JOINED' = 'channelJoined',
  'CHANNEL_REJOINED' = 'channelRejoined',
}

export enum IDFY_SOCKET_EVENT_DISCONNECTED_VALUE {
  'CLOSED' = 'closed',
  'RECONNECT_TIMEOUT' = 'reconnectTimeout',
  'DISCONNECT_REQUEST' = 'disconnectRequest',
  'CHANNEL_ERROR' = 'channelError',
}

export enum IDFY_SOCKET_STATE {
  'PENDING' = 'pending',
  'OPEN' = 'open',
  'CLOSED' = 'closed',
  'RECONNECTING' = 'reconnecting',
}

// Task type constants
export const CAPTURE_TASKS_DATA_CAPTURE = 'data_capture.data_capture';
export const CAPTURE_TASKS_DATA_CAPTURE_PREREQUISITE =
  'data_capture.prerequisite';
export const CAPTURE_TASKS_VERIFY_QA = 'verify.qa';
export const CAPTURE_TASKS_EXTRACT = 'extract.';
export const CAPTURE_TASKS_GROUPED = 'card.group';
export const CAPTURE_TASKS_ASSISTED_VKYC = 'vkyc.assisted_vkyc';
export const CAPTURE_TASKS_ASSISTED_VIDEO_PD = 'assisted_video.video_pd';
export const CAPTURE_TASKS_SV_SERVICE = 'capture.capture_video_activity';
export const CAPTURE_TASKS_SELF_VERIFY_SV_SERVICE =
  'capture_and_verify.capture_video_activity';
export const CAPTURE_TASKS_SV_SERVICE_IN_APP =
  'self_video.capture_video_activity';
export const CAPTURE_TASKS_SELF_VERIFY_SV_SERVICE_IN_APP =
  'self_video.capture_and_verify';
export const CAPTURE_TASKS_SELF_VERIFY_SV_SERVICE_BETA =
  'self_video.capture_and_verify_beta';
export const CAPTURE_TASKS_VERIFY_AADHAAR_XML =
  'verify_with_source.ind_aadhaar_xml';
export const CAPTURE_TASKS_GENERATE_OTP = 'verify_with_source.generate_otp';
export const CAPTURE_TASKS_VALIDATE_OTP = 'verify_with_source.validate_otp';
export const CAPTURE_TASKS_FETCH_AADHAAR_XML = 'fetch.ind_aadhaar_xml';
export const CAPTURE_TASK_FETCH_LOCATION = 'data_capture.location';
export const CAPTURE_TASK_RESOLVE_LOCATION = 'resolve.location';
export const CAPTURE_TASK_RESOLVE_GEOLOCATION = 'resolve.google_geolocation';

export const SV_CAPTURE_AND_VERIFY_MAX_ATTEMPTS = 5;
// Template type constants
export const CAPTURE_TEMPLATES_CARD = 'card';
export const CAPTURE_TEMPLATES_TEXT = 'text';
export const CAPTURE_TEMPLATES_IMAGE = 'image';
export const CAPTURE_TEMPLATES_BUTTON = 'button';
export const CAPTURE_TEMPLATES_GROUP = 'group';
export const CAPTURE_TEMPLATES_FORM = 'form';
export const CAPTURE_TEMPLATES_FILE_PICKER = 'filepicker';
export const CAPTURE_TEMPLATES_ANCHOR = 'anchor';
export const CAPTURE_TEMPLATES_RADIO = 'radio';
export const CAPTURE_TEMPLATES_DROPDOWN = 'dropdown';
export const CAPTURE_TEMPLATES_CHECKBOX = 'checkbox';
export const CAPTURE_TEMPLATES_DOCUMENT = 'document';
export const CAPTURE_TEMPLATES_SECTION = 'section';

// videoComponent: Screenshot Resolution Config
export const LOW_RES_SCREENSHOT_WIDTH = 480;
export const LOW_RES_SCREENSHOT_HEIGHT = 360;
export const HIGH_RES_SCREENSHOT_WIDTH = 640;
export const HIGH_RES_SCREENSHOT_HEIGHT = 480;
export const SCREENSHOT_COMPRESSION_QUALITY = 1.0;

// videoComponent: RAGStatusThresholds
export const AVERAGE_NETWORK_THRESHOLD = 50;
export const STRONG_NETWORK_THRESHOLD = 100;
export const UNSTABLE_NETWORK_RTT_THRESHOLD = 200;

// Page Component
export const INTRO_PAGE_BOX_CONTAINER = 'idfy-intro-container';

// FilePicker: Upload file size
export const MAX_DOCUMENT_UPLOAD_SIZE = 5242880; // 5 MB = 5242880 bytes

// Aadhaar XML
export const INPUT_TYPE_AADHAAR_NUMBER = 'aadhaar_number';
export const INPUT_TYPE_VID = 'vid';

export const MAX_SS_WIDTH = 1080;
export const MAX_SS_HEIGHT = 1920;

export enum OTPSTATES {
  OTP_SUCCESS = 'otp_sucess',
  OTP_EXPIRED = 'otp_expired',
  OTP_VERIFICATION = 'otp_verification',
  OTP_INVALID = 'otp_invalid',
  ENTER_OTP = 'enter_otp',
  OTP_ATTEMPTS_EXHAUSTED = 'otp_attempts_exhausted',
  TERMS_OF_SERVICE = 'terms_of_service',
}

export const IOS_VERSION = 'IOS version';
export const NO_AGENT = 'no_agent';
export const USER_SCHEDULED = 'user_scheduled';

// Booking States
export const INITIAL_STATE = -1;
export const BOOKING_CONFIRMED = 0;

export const WRONG_ZIP_FILE = 'WRONG_ZIP_FILE';
export const EXPIRED_XML_FILE = 'EXPIRED_XML_FILE';
export const INCORRECT_SHARE_CODE = 'INCORRECT_SHARE_CODE';
export const INCORRECT_DOCUMENT = 'INCORRECT_DOCUMENT';
export const UNREADABLE_DOCUMENT = 'UNREADABLE_DOCUMENT';
export const SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG';
export const VERIFICATION_TIMEOUT = 'VERIFICATION_TIMEOUT';
export const USER_TOKEN_MISSING = 'USER_TOKEN_MISSING';
export const INVALID_TOKEN = 'INVALID_TOKEN';
export const FAILED_TO_FETCH_DOC = 'FAILED_TO_FETCH_DOC';
export const DIGILOCKER_ERROR = 'DIGILOCKER_ERROR';
export const DOCUMENT_TAMPERED = 'DOCUMENT_TAMPERED';
export const USER_DENIED_ACCESS = 'USER_DENIED_ACCESS';
export const INSUFFICIENT_SCOPE = 'INSUFFICIENT_SCOPE';

export const ERROR_CODES: Map<string, string> = new Map([
  [WRONG_ZIP_FILE, 'WRONG_ZIP_FILE'],
  [EXPIRED_XML_FILE, 'EXPIRED_XML_FILE'],
  [INCORRECT_SHARE_CODE, 'INCORRECT_SHARE_CODE'],
  [INCORRECT_DOCUMENT, 'INCORRECT_DOCUMENT'],
  [UNREADABLE_DOCUMENT, 'UNREADABLE_DOCUMENT'],
  [SOMETHING_WENT_WRONG, 'SOMETHING_WENT_WRONG'],
  [VERIFICATION_TIMEOUT, 'VERIFICATION_TIMEOUT'],
  [USER_TOKEN_MISSING, 'USER_TOKEN_MISSING'],
  [INVALID_TOKEN, 'INVALID_TOKEN'],
  [FAILED_TO_FETCH_DOC, 'FAILED_TO_FETCH_DOC'],
  [DIGILOCKER_ERROR, 'DIGILOCKER_ERROR'],
  [DOCUMENT_TAMPERED, 'DOCUMENT_TAMPERED'],
  [USER_DENIED_ACCESS, 'USER_DENIED_ACCESS'],
  [INSUFFICIENT_SCOPE, 'INSUFFICIENT_SCOPE'],
]);

// Media Constraints
export const MEDIA_CONSTRAINTS =
  'eyJhdWRpbyI6eyJlY2hvQ2FuY2VsbGF0aW9uIjp0cnVlLCJub2lzZVN1cHByZXNzaW9uIjpmYWxzZX0sInZpZGVvIjp7IndpZHRoIjp7Im1heCI6NjQwLCJpZGVhbCI6NDgwLCJtaW4iOjQ4MH0sImhlaWdodCI6eyJtYXgiOjQ4MCwiaWRlYWwiOjM2MCwibWluIjozNjB9LCJmcmFtZVJhdGUiOnsiaWRlYWwiOjgsIm1heCI6MTAsIm1pbiI6MX19fQ== ';

export const SCREEN_OVERLAY_SIZE = {
  desktop: {
    width: {
      off: 1,
      a4: 0.4,
      rectangle: 0.7,
      selfie: 0.4,
    },
    height: {
      off: 1,
      a4: 0.94,
      rectangle: 0.7,
      selfie: 0.9,
    },
  },
  mobile: {
    width: {
      off: 1,
      a4: 0.7,
      rectangle: 0.86,
      selfie: 0.74,
    },
    height: {
      off: 1,
      a4: 0.85,
      rectangle: 0.45,
      selfie: 0.8,
    },
  },
};

export const SCREEN_OVERLAYS = [
  'selfie_overlay',
  'rectangle_overlay',
  'a4_overlay',
  'off_overlay',
];

export const deniedPermissionBrowserSettings = {
  androidWebView: {
    location: {
      message: [
        [
          'GO_TO_SETTINGS',
          'APPS',
          'SELECT_APP_IN_USE',
          'PERMISSIONS',
          'LOCATION',
          'TOGGLE_ALLOW_WHILE_USING_APP',
        ],
      ],
      helperText: ['TURN_ON_PRECISE_LOCATION'],
    },
    camera: {
      message: [
        [
          'GO_TO_SETTINGS',
          'APPS',
          'SELECT_APP_IN_USE',
          'PERMISSIONS',
          'CAMERA',
          'TOGGLE_ALLOW_WHILE_USING_APP',
        ],
      ],
    },
    microphone: {
      message: [
        [
          'GO_TO_SETTINGS',
          'APPS',
          'SELECT_APP_IN_USE',
          'PERMISSIONS',
          'MICROPHONE',
          'TOGGLE_ALLOW_WHILE_USING_APP',
        ],
      ],
    },
  },
  chrome: {
    general: {
      message: [['CLICK_ON_RESET_ICON', 'PERMISSIONS', 'RESET_PERMISSIONS']],
    },
  },
  iosWebView: {
    location: {
      message: [
        [
          'GO_TO_SETTINGS',
          'SELECT_APP_IN_USE',
          'LOCATION',
          'SELECT_WHILE_USING_APP',
        ],
      ],
      helperText: ['TURN_ON_PRECISE_LOCATION'],
    },
    camera: {
      message: [
        ['GO_TO_SETTINGS', 'SELECT_APP_IN_USE', 'TOGGLE_CAMERA_ACCESS'],
      ],
    },
    microphone: {
      message: [
        ['GO_TO_SETTINGS', 'SELECT_APP_IN_USE', 'TOGGLE_MICROPHONE_ACCESS'],
      ],
    },
  },
  safari: {
    location: {
      message: [
        [
          'CLICK_RESET_ICON_IOS',
          'WEBSITE_SETTINGS',
          'LOCATION',
          'SELECT_ALLOW',
        ],
      ],
    },
    camera: {
      message: [
        ['CLICK_RESET_ICON_IOS', 'WEBSITE_SETTINGS', 'CAMERA', 'SELECT_ALLOW'],
      ],
    },
    microphone: {
      message: [
        [
          'CLICK_RESET_ICON_IOS',
          'WEBSITE_SETTINGS',
          'MICROPHONE',
          'SELECT_ALLOW',
        ],
      ],
    },
  },
  iosChrome: {
    location: {
      message: [
        [
          'GO_TO_SETTINGS',
          'SELECT_CHROME',
          'LOCATION',
          'SELECT_WHILE_USING_APP',
        ],
      ],
      helperText: ['TURN_ON_PRECISE_LOCATION'],
    },
    camera: {
      message: [['GO_TO_SETTINGS', 'SELECT_CHROME', 'TOGGLE_CAMERA_ACCESS']],
    },
    microphone: {
      message: [
        ['GO_TO_SETTINGS', 'SELECT_CHROME', 'TOGGLE_MICROPHONE_ACCESS'],
      ],
    },
  },
  windows: {
    general: {
      message: [['CLICK_ON_RESET_ICON', 'PERMISSIONS', 'RESET_PERMISSIONS']],
    },
  },
  macSafari: {
    location: {
      message: [
        [
          'CLICK_SAFARI_MENU',
          'PREFERENCES',
          'LOCATION',
          'CLICK_WEBSITES_TAB',
          'SELECT_LOCATION',
          'SET_CAPTURE_IDFY_TO_ASK',
        ],
      ],
    },
    camera: {
      message: [
        [
          'CLICK_SAFARI_MENU',
          'PREFERENCES',
          'CAMERA',
          'CLICK_WEBSITES_TAB',
          'SELECT_CAMERA',
          'SET_CAPTURE_IDFY_TO_ASK',
        ],
      ],
    },
    microphone: {
      message: [
        [
          'CLICK_SAFARI_MENU',
          'PREFERENCES',
          'MICROPHONE',
          'CLICK_WEBSITES_TAB',
          'SELECT_MICROPHONE',
          'SET_CAPTURE_IDFY_TO_ASK',
        ],
      ],
    },
  },
};

export const deniedPermissionSystemSettings = {
  android: {
    location: {
      message: [['GO_TO_SETTINGS', 'LOCATION', 'TOGGLE_USE_LOCATION']],
    },
    camera: {
      message: [
        ['GO_TO_SETTINGS', 'PRIVACY_SECURITY_PRIVACY', 'TOGGLE_CAMERA_ACCESS'],
      ],
    },
    microphone: {
      message: [
        [
          'GO_TO_SETTINGS',
          'PRIVACY_SECURITY_PRIVACY',
          'TOGGLE_MICROPHONE_ACCESS',
        ],
      ],
    },
  },
  ios: {
    location: {
      message: [
        [
          'GO_TO_SETTINGS',
          'PRIVACY_SECURITY',
          'LOCATION_SERVICES',
          'TURN_ON_LOCATION_SERVICES',
        ],
        [
          'GO_TO_SETTINGS',
          'PRIVACY_SECURITY',
          'LOCATION_SERVICES',
          'SAFARI_WEBSITES',
          'SELECT_WHILE_USING_APP',
        ],
      ],
      helperText: ['TOGGLE_ON_PRECISE_LOCATION'],
    },
  },
  windows: {
    location: {
      message: [
        ['GO_TO_SETTINGS', 'PRIVACY', 'LOCATION', 'TOGGLE_ALLOW_LOCATION'],
      ],
    },
    camera: {
      message: [['GO_TO_SETTINGS', 'PRIVACY', 'CAMERA', 'TOGGLE_ALLOW_CAMERA']],
    },
    microphone: {
      message: [
        ['GO_TO_SETTINGS', 'PRIVACY', 'MICROPHONE', 'TOGGLE_ALLOW_MICROPHONE'],
      ],
    },
  },
  mac: {
    location: {
      message: [
        [
          'GO_TO_SETTINGS',
          'PRIVACY_SECURITY',
          'LOCATION_SERVICES',
          'TOGGLE_ON_FOR_APP',
        ],
      ],
    },
    microphone: {
      message: [
        [
          'GO_TO_SETTINGS',
          'PRIVACY_SECURITY',
          'MICROPHONE',
          'TOGGLE_ON_FOR_APP',
        ],
      ],
    },
    camera: {
      message: [
        ['GO_TO_SETTINGS', 'PRIVACY_SECURITY', 'CAMERA', 'TOGGLE_ON_FOR_APP'],
      ],
    },
  },
};

export const PERMISSION_STATUS_MAP = {
  IN_PROGRESS: 'permission-in-progress',
  SUCCESS: 'permission-success',
  WARN: 'permission-warn',
  DENIED: 'permission-denied',
};

export const SESSION_PERMISSION_DENIED = 'session:permissions_denied';

export const SESSION_VALIDATION_ERROR = 'session:validation_error';

export const SESSION_ERROR_ENCOUNTERED = 'session:error_encountered';
