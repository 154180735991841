import React, { ErrorInfo, ReactNode } from 'react';
import './error.css';
import { connect } from 'react-redux';
import { History } from 'history';
import { DataService } from '../../services/DataService';
import {
  ERROR_TYPE_TIMEOUT,
  ERROR_TYPE_INVALID_LINK,
  ERROR_TYPE_INVALID_LOCATION,
  VALIDATION_LOCATION,
  ERROR_TYPE_COMPATIBILITY,
  ERROR_TYPE_BROWSER_NOT_SUPPORTED,
  ERROR_TYPE_LOCATION_DENIED,
  ERROR_TYPE_NO_AGENT,
  ERROR_TYPE_SLOTS_UNAVAILABLE,
  LOCATION,
  ERROR_TYPE_PROXY_CHECK,
  DF_AUTHENTICATION_FAILED,
  SHOW_REDIRECTING_OVERLAY,
  ERROR_TYPE_REMOVED_FROM_QUEUE,
  ERROR_TYPE_SOMETHING_WENT_WRONG,
} from '../../helpers/constants';
import { AppState } from '../../store';
import {
  ErrorDetail,
  OverlayOptions,
  RedirectingOverlayOption,
} from '../../store/Error/types';
import PoorConnectionComponent from '../PoorConnection';
import InvalidLinkComponent from '../InvalidLinkComponent';
import { ValidationService } from '../../services/ValidationService';
import { Rg4JsWrapperService } from '../../helpers/raygunWrapper';
import BrowserNotSupported from '../BrowserNotSupported';
import { log, LogLevel, logPageVisit } from '../../helpers/loggerWrapper';
import { getTatStartRef, tryAgain } from '../../helpers';
import { ROUTE_THANKYOU } from '../../helpers/routes';
import SimpleOverlay from '../SimpleOverlay';
import { buttonActionTypes } from '../InstructionBasedOverlay';
import PermissionDeniedComponent from '../PermissionsPage/PermissionDenied';
import i18n from '../../i18n/i18n';
import RedirectingLoaderComponent from '../RedirectingLoader';
import RemovedFromQueue from '../RemovedFromQueue';

const translate = i18n.t.bind(i18n);
interface StateProps {
  error: ErrorDetail;
  overlay: OverlayOptions;
  redirectingOverlay: RedirectingOverlayOption;
}

interface OwnProps {
  history: History;
  children?: React.ReactChild;
}
interface State {
  errorOccurred: boolean;
}

interface Props extends StateProps, OwnProps {}

class ErrorHandlerComponent extends React.Component<Props, State> {
  loggerStartTime = 0;

  unhandledError: boolean;

  constructor(props: Props) {
    super(props);
    this.state = { errorOccurred: false };
    this.unhandledError = false;
    this.clearError = this.clearError.bind(this);
    this.actionCallback = this.actionCallback.bind(this);
    this.handleRetryClick = this.handleRetryClick.bind(this);
  }

  componentDidMount(): void {
    DataService.EndCommunications().then(
      // eslint-disable-next-line
      () => {},
      // eslint-disable-next-line
      () => {},
    );
  }

  componentDidUpdate(): void {
    const { errorOccurred } = this.state;
    const { error } = this.props;
    if (
      (!error.errorType && (errorOccurred || error.error)) ||
      error.errorType === ERROR_TYPE_TIMEOUT ||
      error.errorType === ERROR_TYPE_INVALID_LINK
    ) {
      DataService.EndCommunications().then(
        // eslint-disable-next-line
        () => {},
        // eslint-disable-next-line
        () => {},
      );
    }
    if (
      DataService.IsVKycStarted() &&
      error.errorType === ERROR_TYPE_LOCATION_DENIED
    ) {
      // eslint-disable-next-line
      DataService.endVKyc();
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.loggerStartTime = getTatStartRef();
    this.setState({ errorOccurred: true });
    Rg4JsWrapperService.Log({
      callType: 'send',
      objectValue: { error, errorInfo },
    });
  }

  handleRetryClick = (): void => {
    const { error } = this.props;
    const pageIndex = error.pageIndex ? error.pageIndex : 0;
    this.clearError();
    if (DataService.IsVKycStarted()) {
      // eslint-disable-next-line
      DataService.SendVKycTaskData().then(() => {});
    } else {
      // eslint-disable-next-line
      DataService.ValidatePage(pageIndex).then((data) => {
        if (pageIndex === 0) {
          DataService.loadFirstPage(data);
        }
      });
    }
  };

  actionCallback(action: buttonActionTypes): void {
    const { history } = this.props;
    if (action === 'retry') {
      tryAgain();
      log(LogLevel.Info, {
        serviceCategory: 'Capture',
        service: 'UnhandledError',
        eventType: 'Clicked',
        eventName: 'SomethingWentWrong_Retry',
        component: 'ErrorHandlerComponent',
        eventSource: 'handleClick',
      });
    } else if (action === 'goBack') {
      this.clearError();
      log(LogLevel.Info, {
        serviceCategory: 'Capture',
        service: 'FetchSchedulingConfig',
        eventType: 'Clicked',
        eventName: 'GoBack',
        component: 'ErrorHandlerComponent',
        eventSource: 'handleClick',
      });
      history.push({
        pathname: ROUTE_THANKYOU,
        search: window.location.search,
      });
    }
  }

  clearError(): void {
    // Clear Error
    this.setState({ errorOccurred: false }, () => {
      DataService.ClearError();
    });
  }

  render(): ReactNode {
    const { error, children, redirectingOverlay, overlay, history } =
      this.props;
    const { errorOccurred } = this.state;
    if (!errorOccurred && !error.error) {
      return children;
    }
    if (error.errorType === ERROR_TYPE_TIMEOUT) {
      return <PoorConnectionComponent />;
    }
    if (error.errorType === ERROR_TYPE_INVALID_LINK) {
      return <InvalidLinkComponent />;
    }
    if (redirectingOverlay.errorType === SHOW_REDIRECTING_OVERLAY) {
      return (
        <RedirectingLoaderComponent
          IsWaitingForSubmission
          text={redirectingOverlay.redirectingText}
          showBackButton
          showHeader
          handleClick={redirectingOverlay.handleClick}
          history={this.props.history}
        />
      );
    }
    if (overlay.errorType === ERROR_TYPE_INVALID_LOCATION) {
      return ValidationService.GetErrorComponent(VALIDATION_LOCATION);
    }
    if (overlay.errorType === ERROR_TYPE_PROXY_CHECK) {
      return ValidationService.GetErrorComponent(ERROR_TYPE_PROXY_CHECK);
    }
    if (overlay.errorType === DF_AUTHENTICATION_FAILED) {
      return (
        <SimpleOverlay
          showHeader={overlay.showHeader ? overlay.showHeader : false}
          titleConfig={{
            primaryText: overlay.titleConfig.primaryText,
            secondaryText: overlay.titleConfig.secondaryText
              ? overlay.titleConfig.secondaryText
              : '',
            tertiaryText: overlay.titleConfig.tertiaryText,
            titleSvg: overlay.titleConfig.titleSvg
              ? overlay.titleConfig.titleSvg
              : '',
            displayLogo: overlay.titleConfig.displayLogo,
            justifySecondaryText: overlay.titleConfig.justifySecondaryText,
          }}
          buttonConfig={overlay.buttons}
          showCrossButton={overlay.showCrossBtn}
          actionCallback={overlay.actionCallback}
          bottomNote={overlay.bottomNote}
          handleCrossBtn={overlay.handleCrossBtn}
          handleGoBack={overlay.handleGoBack}
          headerConfig={overlay.headerConfig}
          history={history}
          loaderConfig={overlay.loaderConfig}
        />
      );
    }
    if (
      error.errorType === ERROR_TYPE_BROWSER_NOT_SUPPORTED ||
      error.errorType === ERROR_TYPE_COMPATIBILITY
    ) {
      DataService.sendErrorEncountered(
        {
          error_encountered: ERROR_TYPE_BROWSER_NOT_SUPPORTED,
        },
        true,
      );
      return <BrowserNotSupported errorType={error.errorType} />;
    }
    if (error.errorType === ERROR_TYPE_LOCATION_DENIED) {
      return (
        <PermissionDeniedComponent
          deniedPermissions={[
            { label: translate('LOCATION_DENIED_KEY'), key: LOCATION },
          ]}
          retryPermissions={this.handleRetryClick}
          showLoader={false}
          showHeader
          history={history}
        />
      );
    }
    if (overlay.errorType === ERROR_TYPE_NO_AGENT) {
      return (
        <SimpleOverlay
          titleConfig={{
            primaryText: overlay.titleConfig.primaryText,
            secondaryText: overlay.titleConfig.secondaryText
              ? overlay.titleConfig.secondaryText
              : '',
            tertiaryText: overlay.titleConfig.tertiaryText,
            titleSvg: overlay.titleConfig.titleSvg
              ? overlay.titleConfig.titleSvg
              : '',
            displayLogo: overlay.titleConfig.displayLogo,
            justifySecondaryText: overlay.titleConfig.justifySecondaryText,
          }}
          buttonConfig={overlay.buttons ? overlay.buttons : []}
          bottomNote={overlay.bottomNote}
          showHeader
          actionCallback={
            overlay.actionCallback ? overlay.actionCallback : undefined
          }
          headerConfig={{
            appBackground: overlay.headerConfig
              ? overlay.headerConfig.appBackground
              : '',
            displayBackButton: overlay.headerConfig
              ? overlay.headerConfig.displayBackButton
              : false,
            isErrorScreen: overlay.headerConfig
              ? overlay.headerConfig.isErrorScreen
              : true,
            displayExitButton:
              overlay.headerConfig &&
              typeof overlay.headerConfig.displayExitButton === 'boolean'
                ? overlay.headerConfig.displayExitButton
                : true,
          }}
          handleGoBack={overlay.handleGoBack}
          handleCrossBtn={overlay.handleCrossBtn}
          history={history}
          showCrossButton={overlay.showCrossBtn}
          loaderConfig={overlay.loaderConfig}
        />
      );
    }
    if (overlay.errorType === ERROR_TYPE_REMOVED_FROM_QUEUE) {
      return (
        <RemovedFromQueue
          showCrossButton={overlay.showCrossBtn}
          actionCallback={
            overlay.actionCallback ? overlay.actionCallback : undefined
          }
          headerConfig={{
            appBackground: overlay.headerConfig
              ? overlay.headerConfig.appBackground
              : '',
            displayBackButton: overlay.headerConfig
              ? overlay.headerConfig.displayBackButton
              : false,
            isErrorScreen: overlay.headerConfig
              ? overlay.headerConfig.isErrorScreen
              : true,
            displayExitButton:
              overlay.headerConfig &&
              typeof overlay.headerConfig.displayExitButton === 'boolean'
                ? overlay.headerConfig.displayExitButton
                : true,
          }}
          handleGoBack={overlay.handleGoBack}
          handleCrossBtn={overlay.handleCrossBtn}
        />
      );
    }
    if (overlay.errorType === ERROR_TYPE_SLOTS_UNAVAILABLE) {
      return (
        <SimpleOverlay
          titleConfig={{
            primaryText: overlay.titleConfig.primaryText,
            secondaryText: overlay.titleConfig.secondaryText
              ? overlay.titleConfig.secondaryText
              : '',
            tertiaryText: overlay.titleConfig.tertiaryText,
            titleSvg: overlay.titleConfig.titleSvg
              ? overlay.titleConfig.titleSvg
              : '',
            justifySecondaryText: overlay.titleConfig.justifySecondaryText,
          }}
          buttonConfig={overlay.buttons ? overlay.buttons : []}
          showHeader={false}
          actionCallback={this.actionCallback}
          showCrossButton={overlay.showCrossBtn}
          loaderConfig={
            overlay.loaderConfig
              ? overlay.loaderConfig
              : { displayLoader: false, loaderTimer: 0 }
          }
        />
      );
    }
    if (!error.errorType && (errorOccurred || error.error)) {
      log(
        LogLevel.Error,
        {
          serviceCategory: 'Capture',
          service: 'UnhandledError',
          eventType: 'Exception',
          eventName: 'SomethingWentWrong',
          component: 'ErrorHandlerComponent',
          eventSource: 'componentDidMount',
          exceptionName: '',
          exceptionDescription: 'Something Went Wrong',
        },
        {
          error,
        },
      );
      this.unhandledError = true;
      log(LogLevel.Info, {
        serviceCategory: 'Capture',
        service: '',
        eventType: '',
        eventName: 'SomethingWentWrong',
        component: 'ErrorHandlerComponent',
        eventSource: 'render',
      });
      logPageVisit('SomethingWentWrong', 'ErrorHandlerComponent', '', true);
      DataService.sendErrorEncountered({
        error_encountered: ERROR_TYPE_SOMETHING_WENT_WRONG,
      });
      return (
        <SimpleOverlay
          showHeader
          headerConfig={{
            appBackground: '',
            displayBackButton: false,
            isErrorScreen: true,
          }}
          titleConfig={{
            primaryText: translate('ERROR_NETWORK_DISCONNECTED'),
            secondaryText: translate('ERROR_TRY_AGAIN_DESCRIPTION'),
            titleSvg: 'networkDisconnected',
            justifySecondaryText: false,
          }}
          buttonConfig={[{ label: translate('RETRY_LABEL'), action: 'retry' }]}
          actionCallback={this.actionCallback}
          history={history}
          showCrossButton={overlay.showCrossBtn}
          loaderConfig={{ displayLoader: false, loaderTimer: 0 }}
        />
      );
    }
    return null;
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  error: state.ErrorAndOverlay as ErrorDetail,
  overlay: state.ErrorAndOverlay as OverlayOptions,
  redirectingOverlay: state.ErrorAndOverlay as RedirectingOverlayOption,
});

export default connect<StateProps, {}, OwnProps, AppState>(
  mapStateToProps,
  {},
)(ErrorHandlerComponent);
